// assets
import { IconBrandChrome, IconHelp, IconUser } from '@tabler/icons';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| USER PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const user = {
    id: 'user-docs-roadmap7',
    type: 'group',
    children: [
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: AccountCircleOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default user;
