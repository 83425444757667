import { createRoot } from 'react-dom/client';
// third party
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
// project imports
import App from 'App';
import AppProvider from 'context/AppProvider';
import { store } from 'store';
// style + assets
import 'assets/scss/style.scss';
import config from './config';

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
// Your function to clear console logs
function clearConsoleLogs() {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        // Override the console.log method to do nothing
        console.log = function () {};
        console.warn = function () {};
    }
}
// Call the function before rendering the app.
clearConsoleLogs();
root.render(
    <AppProvider>
        <Provider store={store}>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </Provider>
    </AppProvider>
);
