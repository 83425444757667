import { createStore, compose } from 'redux';
import reducer from './reducer';
import * as actionTypes from './actions/tokenAction';

// ==============================|| REDUX - MAIN STORE ||============================== //
// compose enhancers
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancers());

const persister = 'Ranchers-cafe';
const setToken = (token) => ({
    type: actionTypes.SET_TOKEN,
    token
});

const clearToken = () => ({
    type: actionTypes.CLEAR_TOKEN
});
// const token = localStorage.getItem('token');
// console.log('token in store', token);
// setToken();
export { store, persister, setToken, clearToken };
