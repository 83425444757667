import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './reducers/customizationReducer';
import tokenReducer from './reducers/tokenReducer';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    token: tokenReducer
});

export default reducer;
