import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import React from 'react';
import ProtectedRoute from './ProtectedRoute';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// get all order table
const OrderPage = Loadable(lazy(() => import('views/Order')));

// order routing
const Order = Loadable(lazy(() => import('views/get-order')));

// order routing
const CloseOrder = Loadable(lazy(() => import('views/close-order')));

// report page routing
const ReportPage = Loadable(lazy(() => import('views/report')));

// report page routing
const RiderReport = Loadable(lazy(() => import('views/rider-report')));

// User routing
const UserPage = Loadable(lazy(() => import('views/user')));

// Add User routing
const AddUser = Loadable(lazy(() => import('views/add-user')));

// Customer routing
const CustomerPage = Loadable(lazy(() => import('views/customer')));

// Branch routing
const BranchPage = Loadable(lazy(() => import('views/branch')));

// Add Branch routing
const AddBranch = Loadable(lazy(() => import('views/add-branch')));
``;

// get all taxes
const ViewTaxes = Loadable(lazy(() => import('views/tax')));

// add tax
const AddTax = Loadable(lazy(() => import('views/add-tax')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <ProtectedRoute>
            <MainLayout />
        </ProtectedRoute>
    ),
    children: [
        {
            path: '/',
            element: <SamplePage />
        },
        {
            path: 'riderTracking',
            element: <SamplePage />
        },
        {
            path: 'viewOrder',
            element: <OrderPage />
        },
        {
            path: 'order',
            element: <Order />
        },
        {
            path: 'closeOrder',
            element: <CloseOrder />
        },
        {
            path: 'report',
            element: <ReportPage />
        },
        {
            path: 'riderReport',
            element: <RiderReport />
        },
        {
            path: 'users',
            element: <UserPage />
        },
        {
            path: 'addUser',
            element: <AddUser />
        },
        {
            path: 'customers',
            element: <CustomerPage />
        },
        {
            path: 'branch',
            element: <BranchPage />
        },
        {
            path: 'addBranch',
            element: <AddBranch />
        },
        {
            path: 'taxes',
            element: <ViewTaxes />
        },
        {
            path: 'addTax',
            element: <AddTax />
        }
    ]
};

export default MainRoutes;
