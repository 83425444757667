// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
// constant
const icons = { IconBrandChrome, IconHelp };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'riderTracking',
            title: 'Rider Tracking',
            type: 'item',
            url: '/riderTracking',
            icon: LocationSearchingIcon,
            breadcrumbs: false
        }
    ]
};

export default other;
