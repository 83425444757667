// assets
import { IconBrandChrome, IconHelp, IconUser } from '@tabler/icons';
import CribOutlinedIcon from '@mui/icons-material/CribOutlined';
import ViewListIcon from '@mui/icons-material/ViewList';
// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| USER PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const order = {
    id: 'user-docs-roadmap8',
    type: 'group',
    children: [
        {
            id: 'vieworder',
            title: 'Order Table',
            type: 'item',
            url: '/viewOrder',
            icon: ViewListIcon,
            breadcrumbs: false
        }
    ]
};

export default order;
