import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import Report from './Report';
import user from './user';
import branch from './branch';
import order from './order';
import viewOrder from './viewOrder';
import taxViewer from './viewTax';
import closeOrder from './closeOrder';
import RiderReport from './RiderReport';
import customer from './customer';
// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
    // items: [other, viewOrder, order, closeOrder, Report, RiderReport, user, customer, branch, taxViewer]
    items: [other, viewOrder, order, closeOrder, Report, RiderReport, user, customer, branch, taxViewer]
};

export default menuItems;
