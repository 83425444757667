// assets
import { IconBrandChrome, IconHelp, IconUser } from '@tabler/icons';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| USER PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const RiderReport = {
    id: 'user-docs-roadmap6',
    type: 'group',
    children: [
        {
            id: 'riderReport',
            title: 'Rider Report',
            type: 'item',
            url: '/riderReport',
            icon: AssessmentOutlinedIcon,
            breadcrumbs: false
        }
    ]
};

export default RiderReport;
