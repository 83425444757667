// assets
import { IconBrandChrome, IconHelp, IconUser } from '@tabler/icons';
import CribOutlinedIcon from '@mui/icons-material/CribOutlined';
import FlakyIcon from '@mui/icons-material/Flaky';
// constant
const icons = { IconBrandChrome, IconHelp, IconUser };

// ==============================|| USER PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const closeOrder = {
    id: 'user-docs-roadmap2',
    type: 'group',
    children: [
        {
            id: 'closeOrder',
            title: 'Closed Orders',
            type: 'item',
            url: '/closeOrder',
            icon: FlakyIcon,
            breadcrumbs: false
        }
    ]
};

export default closeOrder;
