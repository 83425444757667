import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ApolloProvider } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { setToken } from './store/index';
import useSound from 'use-sound';
import AppContext from './context/AppContext';
import { useEffect, useState, useContext } from 'react';
// routing
import Routes from 'routes';

import themes from 'themes';
import client from './apolloClient';
import { Howl, Howler } from 'howler';
// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { ContentPasteOffOutlined } from '@mui/icons-material';
import OneSignal from 'react-onesignal';
import pushNotificationSound from './assets/Audio-clips/push-notifications-sound.wav';
// ==============================|| APP ||============================== //

const App = () => {
    const [play] = useSound(pushNotificationSound, { interrupt: true });
    const dispatch = useDispatch();
    const [initialized, setInitialized] = useState(false);
    const { counter } = useContext(AppContext);
    const customization = useSelector((state) => state.customization);
    const { token } = useSelector((state) => state.token);
    useEffect(() => {
        let token = localStorage.getItem('token');
        let accessToken = {
            token
        };
        dispatch(setToken(accessToken));
    }, []);

    useEffect(() => {
        async function oneSignalInitialized() {
            await OneSignal?.init({ appId: 'e29f05c4-f085-4576-96e0-a170f82cbf67', allowLocalhostAsSecureOrigin: true });
            OneSignal?.showSlidedownPrompt();
            setInitialized(true);

            OneSignal.on('notificationDisplay', (event) => {
                const notification = event.data;
                // Process the notification data or trigger your custom function here
                console.log('Notification Received:', notification);
                // play();
            });
            OneSignal.on('notificationOpen', (event) => {
                console.log('Notification has been clicked');
            });
        }

        oneSignalInitialized();
    }, []);

    useEffect(() => {
        async function oneSignalSetup() {
            try {
                let userObj = localStorage.getItem('user');
                userObj = JSON.parse(userObj);
                let externalUserId;
                if (userObj) {
                    console.log('user....', await OneSignal?.getUserId());
                    await OneSignal?.getExternalUserId().then((userId) => {
                        console.log('external user id..', userId);
                        // externalUserId = userId;
                        if (!!userId) {
                            externalUserId = userId;
                        }
                    });
                    if (!externalUserId) {
                        console.log('user id not found...');
                        await OneSignal?.setExternalUserId(userObj?.userId)
                            .then((res) => console.log('attached external user id..', res))
                            .catch((Err) => console.log('error...', Err));
                    } else {
                        console.log('user id found', externalUserId);
                    }
                    await OneSignal?.getSubscription(async (subscription) => {
                        if (!subscription) {
                            await OneSignal?.setSubscription(true);
                        } else {
                            console.log('user subscription...', subscription);
                        }
                    });
                }
            } catch (err) {
                console.log('error ...', err);
            }
        }
        console.log('counter value...', counter);
        if (initialized) {
            oneSignalSetup();
        }
    }, [counter, initialized]);
    // useEffect(() => {
    //     playSound();
    // }, []);

    // const soundPlay = (src) => {
    //     const sound = new Howl({
    //         src,
    //         html5: true
    //         // volume: 0.2
    //     });
    //     sound.play();
    //     console.log('The sound function is called');
    // };

    return (
        <ApolloProvider client={client}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={themes(customization)}>
                    <CssBaseline />
                    <NavigationScroll>
                        {/* <button onClick={play}></button> */}
                        <Routes />
                    </NavigationScroll>
                </ThemeProvider>
            </StyledEngineProvider>
        </ApolloProvider>
    );
};

export default App;
