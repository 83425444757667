import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getToken } from '../utils/getToken';
import auth from '../utils/auth';
function ProtectedRoute(props) {
    const token = auth.getToken();
    // const token = true;

    return token?.accessToken ? props.children : <Navigate to={'/login'} />;
}

export default ProtectedRoute;
